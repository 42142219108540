import {
  InterpreterObjectType,
  TransformedTemplate,
  TargetInstance,
} from "hooks/useGlobalIndex/types";

export default function checkIssuerAlias(
  issuer: string,
  interpreter: Record<string, InterpreterObjectType> | null,
  contractTemplates: Record<string, TransformedTemplate>,
  contractInstance: Record<string, TargetInstance>
) {
  const allTemplates = {
    ...interpreter,
    ...contractTemplates,
    ...contractInstance,
  };

  const actualTxId = issuer.includes("{")
    ? issuer.slice(issuer.indexOf("{") + 22, issuer.indexOf('"}'))
    : issuer;

  const cleanedTxId = actualTxId.startsWith("0x")
    ? actualTxId.substring(2)
    : actualTxId;

  // Check if the txid exists in the combined templates
  if (allTemplates.hasOwnProperty(cleanedTxId)) {
    return allTemplates[cleanedTxId].alias;
  }

  // If not found, return an appropriate message (optional)
  return undefined;
}
