import "./styles.css";
import Typography from "@mui/material/Typography";
import CopyIcon from "components/CopyIcon";
import { Fade, Tooltip } from "@mui/material";
import { memo, useState, useEffect } from "react";
import Collapse from "@mui/material/Collapse";
import TableCell from "@mui/material/TableCell";
import IconButton from "@mui/material/IconButton";
import yaml from "js-yaml";
import { isHexHash } from "utils/isHexHash";
import { useSearchParams } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { TargetInstance } from "hooks/useGlobalIndex/types";
import CardTitle from "./CardTitle";
import checkIssuerAlias from "utils/checkIssuerAlias";
import { Alias } from "shared/components";
import { useGlobalIndex } from "hooks/useGlobalIndex";
import {
  L2TxLogMuiCard,
  MOBILE_WIDTH,
} from "components/TableContainer/CustomizedMui";
import Tables from "./Tables";
import handleTxIds from "utils/handleTxIds";
// import {useExtractFilesFromUrl} from "hooks/useExtractFilesFromUrl"
import checkIfTablesVisible from "utils/checkIfTablesVisible";
import { REACT_APP_CLAIM_TABLES } from "conf";
type ContactProps = {
  width: number;
  data: TargetInstance;
};
export const formatKey = (key: string) => {
  return key
    .replace(/_/g, " ") // Replace underscores with spaces
    .replace(/([A-Z])/g, " $1") // Add spaces before capital letters in camelCase
    .toLowerCase()
    .replace(/\b\w/g, (l) => l.toUpperCase()); // Capitalize first letter of each word
};

export const RenderParameters = ({ parameters }: { parameters: any }) => {
  return (
    <>
      {Object.entries(parameters).map(([key, value]) => {
        if (typeof value === "object" && !Array.isArray(value)) {
          return (
            <div key={key} className="grid_cell">
              <Typography
                className="BoldBlueFont16"
                align="left"
                sx={{ marginBottom: "-5px !important" }}
              >
                {formatKey(key)}
              </Typography>
              <RenderParameters parameters={value} />{" "}
            </div>
          );
        } else {
          return (
            <div key={key} className="grid_cell">
              <Typography className="BoldFont16" align="left">
                {formatKey(key)}
              </Typography>
              <div className="L2TxId-row">
                <Typography className="BoldFont14" align="center">
                  {Array.isArray(value) ? (
                    value.join(", ")
                  ) : isHexHash(String(value)) ? (
                    <div className="L2TxId-row">
                      {" "}
                      <Tooltip
                        componentsProps={{
                          tooltip: {
                            sx: {
                              width: "min-content !important",
                              backgroundColor: "#4f4f72 !important",
                              padding: "0px !important",
                              marginTop: "-1px !important",
                            },
                          },
                        }}
                        disableFocusListener
                        TransitionComponent={Fade}
                        title={<div id="TxId-tooltip">{String(value)}</div>}
                      >
                        <div className="L2TxId-container">
                          {" "}
                          <Typography
                            className="BoldFont14"
                            align="center"
                            sx={{ marginLeft: "3px" }}
                          >
                            {handleTxIds(String(value))}{" "}
                          </Typography>
                        </div>
                      </Tooltip>
                      <CopyIcon data={String(value)} />
                    </div>
                  ) : (
                    String(value)
                  )}
                </Typography>
              </div>
            </div>
          );
        }
      })}
    </>
  );
};
const ContractInstances = (props: ContactProps) => {
  const { width, data } = props;
  const [parameterOpen, setParameterOpen] = useState(false);
  const [tablesOpen, setTablesOpen] = useState(false);
  const [templateAlias, setTemplateAlias] = useState<string | undefined>(
    undefined
  );
  const [instanceAlias, setInstanceAlias] = useState<string | undefined>(
    undefined
  );

  const { contractTemplates, interpreters, contractInstance } =
    useGlobalIndex();
  const [, setSearchParams] = useSearchParams();
  const [yamlData, setYamlData] = useState<any>(null);
  // const { files, error, loading } = useExtractFilesFromUrl(data.packageURL);

  useEffect(() => {
    fetch("/mock.yaml")
      .then((response) => response.text())
      .then((text) => {
        try {
          const data = yaml.load(text); // Load and parse YAML content
          setYamlData(data);
        } catch (e) {
          console.error("Error parsing YAML:", e);
        }
      })
      .catch((error) => {
        console.error("Error loading YAML file:", error);
      });
  }, []);

  useEffect(() => {
    const templateAliasRes = checkIssuerAlias(
      data?.contractModuleId,
      interpreters,
      contractTemplates,
      contractInstance
    );
    if (templateAliasRes) setTemplateAlias(templateAliasRes);
    const instanceAliasRes = checkIssuerAlias(
      data?.instance_id,
      interpreters,
      contractTemplates,
      contractInstance
    );
    if (instanceAliasRes) setInstanceAlias(instanceAliasRes);
  }, [
    data?.contractModuleId,
    data?.instance_id,
    interpreters,
    contractTemplates,
    contractInstance,
  ]);
  return (
    <div
      className="L2txLogCard-container"
      style={{
        marginBottom: width > MOBILE_WIDTH ? "0px" : "20px",
        marginTop: width > MOBILE_WIDTH ? "0px" : undefined,
        height: "100%",
      }}
    >
      <L2TxLogMuiCard sx={{ minWidth: 100, padding: "20px" }}>
        <CardTitle title="Coinweb Contract Instance" />
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <div
            onClick={() => {
              setSearchParams({
                search: data?.contractModuleId,
              });
            }}
          >
            <Typography className="BoldFont16" align="left">
              Contract Module Id
            </Typography>
            <div className="L2TxId-row">
              {templateAlias ? (
                <Alias alias={templateAlias} />
              ) : (
                <Typography className="BoldFont14Hover" align="center">
                  <>
                    <Tooltip
                      componentsProps={{
                        tooltip: {
                          sx: {
                            width: "min-content !important",
                            backgroundColor: "#4f4f72 !important",
                            padding: "0px !important",
                            marginTop: "-1px !important",
                          },
                        },
                      }}
                      disableFocusListener
                      TransitionComponent={Fade}
                      title={
                        <div id="TxId-tooltip">{data?.contractModuleId}</div>
                      }
                    >
                      <div className="L2TxId-container">
                        {handleTxIds(data?.contractModuleId)}
                      </div>
                    </Tooltip>
                  </>
                </Typography>
              )}
            </div>
          </div>
          {instanceAlias ? (
              <div className="grid_cell">
              <Typography className="BoldFont16" align="left">
                Contract Instance Id
              </Typography>
              <div className="L2TxId-row">
            <Alias alias={instanceAlias} />
            </div>
            </div>
          ) : (
            <>
              {data?.instance_id && (
                <div className="grid_cell">
                  <Typography className="BoldFont16" align="left">
                    Contract Instance Id
                  </Typography>
                  <div className="L2TxId-row">
                    <Typography className="BoldFont14" align="center">
                      <>
                        <Tooltip
                          componentsProps={{
                            tooltip: {
                              sx: {
                                width: "min-content !important",
                                backgroundColor: "#4f4f72 !important",
                                padding: "0px !important",
                                marginTop: "-1px !important",
                              },
                            },
                          }}
                          disableFocusListener
                          TransitionComponent={Fade}
                          title={
                            <div id="TxId-tooltip">{data?.instance_id}</div>
                          }
                        >
                          <div className="L2TxId-container">
                            {handleTxIds(data?.instance_id)}
                          </div>
                        </Tooltip>
                      </>
                    </Typography>
                    <CopyIcon data={data?.instance_id} />
                  </div>
                </div>
              )}
              {data?.alias && (
                <div className="grid_cell">
                  <Typography className="BoldFont16" align="left">
                    Contract Instance Alias
                  </Typography>
                  <div className="L2TxId-row">
                    <Typography className="BoldFont14" align="center">
                      {data?.alias}
                    </Typography>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
  
        <TableCell className="operationCollapseCell" sx={{ width: "100%" }}>
          <div
            className="operationCollapseCell"
            onClick={() => setParameterOpen(!parameterOpen)} // Directly invoke handleExpandChange here
            style={{ cursor: "pointer" }} // Add cursor pointer for better UX
          >
            <IconButton aria-label="expand row" size="small">
              {parameterOpen ? (
                <KeyboardArrowUpIcon sx={{ color: "rgb(0, 195, 255)" }} />
              ) : (
                <KeyboardArrowDownIcon sx={{ color: "rgb(0, 195, 255)" }} />
              )}
            </IconButton>
            <Typography className="BlueFont14">Parameters</Typography>
          </div>
        </TableCell>
        <div>
          <TableCell
            sx={{ width: "100% !important" }}
            className="operationCollapseCell"
          >
            <Collapse in={parameterOpen} timeout="auto" unmountOnExit>
              <div className="grid_container">
                <RenderParameters parameters={data?.parameters?.content} />
              </div>
            </Collapse>
          </TableCell>
          {checkIfTablesVisible(yamlData, false) &&
            REACT_APP_CLAIM_TABLES === "true" && (
              <>
                <TableCell
                  className="operationCollapseCell"
                  sx={{ width: "100%" }}
                >
                  <div
                    className="operationCollapseCell"
                    onClick={() => setTablesOpen(!tablesOpen)} // Directly invoke handleExpandChange here
                    style={{ cursor: "pointer" }} // Add cursor pointer for better UX
                  >
                    <IconButton aria-label="expand row" size="small">
                      {tablesOpen ? (
                        <KeyboardArrowUpIcon
                          sx={{ color: "rgb(0, 195, 255)" }}
                        />
                      ) : (
                        <KeyboardArrowDownIcon
                          sx={{ color: "rgb(0, 195, 255)" }}
                        />
                      )}
                    </IconButton>
                    <Typography className="BlueFont14">Tables</Typography>
                  </div>
                </TableCell>
                <TableCell
                  sx={{ width: "100% !important" }}
                  className="operationCollapseCell"
                >
                  <Collapse
                    in={tablesOpen}
                    timeout="auto"
                    unmountOnExit
                    sx={{ width: "100%" }}
                  >
                    <Tables
                      txid={data?.contractModuleId}
                      data={yamlData}
                      isTemplate={false}
                    />
                  </Collapse>
                </TableCell>
              </>
            )}
        </div>
      </L2TxLogMuiCard>
    </div>
  );
};
export default memo(ContractInstances);
